import React from 'react'
import { Spinner, Container, Row, Col } from 'react-bootstrap';

function Loading() {
  return (
    <Container className="mt-5 d-flex justify-content-center">
        <Spinner color="dark" className="mt-5"/>
    </Container>
    
  )
}

export default Loading