import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { Nav, NavDropdown, Button, ListGroup, Dropdown, Accordion, ButtonGroup } from 'react-bootstrap';

import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebaseConfig/firebase';
import { signOut } from "firebase/auth";
import { doc, setDoc, getDocs, collection, where } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebase';

import logo from '../images/logo_escac_black2.png';
import logoMin from '../images/logo_escac_black.png';

import ReactPDF, { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink, Image } from '@react-pdf/renderer';


function LlistesHome() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [valido, setValido] = useState();
    const [lista1, setLista] = useState([]);
    const [email, setEmail] = useState();
    const [nombre, setNombre] = useState();
    const [titulo, setTitulo] = useState();
    const [verPdf, setVerPdf] = useState();

    const [showGrau1g1, setShowGrau1g1] = useState(false);
    const [showGrau1g2, setShowGrau1g2] = useState(false);
    const [showGrau1g3, setShowGrau1g3] = useState(false);
    const [showGrau1g4, setShowGrau1g4] = useState(false);
    const [showGrau2g1, setShowGrau2g1] = useState(false);
    const [showGrau2g2, setShowGrau2g2] = useState(false);
    const [showGrau2g3, setShowGrau2g3] = useState(false);
    const [showGrau2g4, setShowGrau2g4] = useState(false);
    const [showGrau3g1, setShowGrau3g1] = useState(false);
    const [showGrau3g2, setShowGrau3g2] = useState(false);
    const [showGrau3g3, setShowGrau3g3] = useState(false);
    const [showGrau3g4, setShowGrau3g4] = useState(false);


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            setLoading(true);
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            if (user) {
                setEmail(user.email);
                setNombre(user.displayName);
                setValido(true);

            } else {
                setValido(false);
                console.log("user is logged out")
                navigate("/login")

            }
        });

    }, [])

    const handleLogout = () => {
        signOut(auth).then(() => {

            // Sign-out successful.
            navigate("/");
            console.log("Signed out successfully")
        }).catch((error) => {
            // An error happened.
        });
    }


    useEffect(() => {
        const getLista = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'LlistaFoto'))
                const docs = []

                querySnapshot.forEach((doc) => {
                    const versionData = "data:image/jpg;base64," + doc.data().VersionData1 + doc.data().VersionData2
                    const programPlan = doc.data().hed__Program_Plan__c
                    const grupo = doc.data().Grupo__c
                    const term = doc.data().rio_ed__My_Term__c
                    const nombre = doc.data().Contact_First_Name__c
                    const apellidos = doc.data().Contact_Last_Name__c
                    const nombreCompleto = doc.data().nombre_completo

                    let objeto = {
                        nombreCompleto: nombreCompleto,
                        nombre: nombre,
                        apellidos: apellidos,
                        term: term.toString(),
                        grupo: grupo,
                        programPlan: programPlan,
                        versionData: versionData
                    }
                    docs.push(objeto)

                })

                setLista(docs);
                console.log(lista1)

            } catch (error) {
                console.log(error);
            }
        }
        getLista()
    }, [lista1]);


    const MyDocument = (props) => (
        <Document>
            <Page size="A4">
                <View style={styles.container}>
                    <Text style={styles.titol}>{props.titol}</Text>
                    {props.docu
                        .sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                        .map(x => {
                            if (x.term === props.term && x.grupo === props.grupo && (x.programPlan === props.programPlan1 || x.programPlan === props.programPlan2))
                                return (
                                    <>
                                        <View style={styles.card}>
                                            <View style={styles.col1}>
                                                <Text style={styles.text}>{x.nombreCompleto}</Text>
                                            </View>
                                            <View style={styles.col}>
                                                <Image style={styles.foto} src={x.versionData} />
                                            </View>
                                        </View>
                                    </>
                                )
                        })}
                </View>
            </Page>
        </Document>
    );

    const styles = StyleSheet.create({
        titol: {
            marginBottom: 20
        },
        card: {
            flexDirection: 'row',
            marginBottom: 10
        },
        container: {
            flex: 1,
            justifyContent: 'left',
            alignItems: 'left',
            paddingTop: 20,
            paddingLeft: 50,
            paddingBottom: 20
        },
        text: {
            fontSize: 12,
            width: 200
        },
        foto: {
            borderRadius: 50,
            width: 50,
            height: 50
        },
        col: {
            flexDirecction: 'col'
        },
        col1: {
            flexDirecction: 'col'
        }

    });

    return (
        <>
            <Container>
                <Row>
                    <Col lg={8} className="d-flex hidden-700-max">
                        <div className="align-self-center ps-2 pe-2 "><img src={logo} alt="ESCAC" width="400" className="img-fluid" /></div>
                    </Col>

                    {/* PHONE VERSION */}
                    <Col className="d-flex justify-content-center">
                        <div className="ps-2 pe-2 hidden-700-min align-self-center"><img src={logoMin} alt="ESCAC" width="150" className="img-fluid" /></div>
                    </Col>
                    {/* =============== */}

                    <Col lg={3} className="mt-1 ">

                        <Row className="m-0 p-0 ">
                            <Col lg={3} md={2} className="m-0 hidden-700-max">

                                <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-person-circle " viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                    <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                </svg>

                            </Col>
                            <Col lg={9} md={10} className="pe-0 border-start border-dark border-2">
                                <Row className="m-0 p-0">
                                    <Col lg={11} md={11} className="p-0 fs-6">{nombre}</Col>

                                </Row>
                                <Row>
                                    <Col className="mt-1">
                                        <a target="_blank" href="https://outlook.office.com/mail/">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-envelope pb-1" viewBox="0 0 16 16">
                                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                            </svg></a>
                                        &nbsp; <a target="_blank" href="https://outlook.office.com/mail/">Bústia</a>
                                    </Col>
                                    <Col lg={3} md={1} className="p-0 pe-2 pt-1">
                                        <a href="" onClick={handleLogout}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#00000" className="bi bi-arrow-bar-right" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8Zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5Z" />
                                            </svg>
                                        </a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                </Row>

                <div className="mt-0 mt-lg-4 p-3 p-lg-0 p-md-0 p-sm-0 mt-sm-2 border-bottom border-2 border-dark mb-4">
                    <h2 className="m-0 fw-bold ">{titulo} </h2>
                </div>
                <div>
                    <Nav className="sidebar-menu">
                        <Accordion defaultActiveKey="0" className="w-100 border-0 ps-3 pt-3">

                            <Accordion.Item eventKey="0" className="border-0 mb-3">
                                <Accordion.Header  >FOUNDATION</Accordion.Header>
                                <Accordion.Body className="border-0 mt-0" style={{ backgroundColor: '#f1f1f1' }}>
                                    <ListGroup horizontal>
                                        <PDFDownloadLink document={<MyDocument docu={lista1} titol="FOUNDATION G1" term="1" grupo="1" programPlan1="Foundation" programPlan2=""/>} fileName="FOUNDATION_G1.pdf">
                                            <Button variant="light" className="border-5" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>1</Button>
                                        </PDFDownloadLink>
                                        <PDFDownloadLink document={<MyDocument docu={lista1} titol="FOUNDATION G2" term="1" grupo="2" programPlan1="Foundation" programPlan2=""/>} fileName="FOUNDATION_G2.pdf">
                                            <Button variant="light" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>2</Button>
                                        </PDFDownloadLink>
                                        <PDFDownloadLink document={<MyDocument docu={lista1} titol="FOUNDATION G3" term="1" grupo="3" programPlan1="Foundation" programPlan2=""/>} fileName="FOUNDATION_G3.pdf">
                                            <Button variant="light" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>3</Button>
                                        </PDFDownloadLink>
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1" className="border-0 mb-3">
                                <Accordion.Header>GRAU</Accordion.Header>
                                <Accordion.Body className="border-0 mt-0 w-100 pt-2 ps-1 pe-1" style={{ backgroundColor: '#f1f1f1' }}>
                                    <ListGroup style={{ backgroundColor: '#f1f1f1' }}>
                                        <ListGroup.Item className="border-0" style={{ backgroundColor: '#f1f1f1' }}>
                                            <h6 className="mb-0">PRIMER</h6>
                                            <ButtonGroup aria-label="Basic example">
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU PRIMER G1" term="1" grupo="1" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_PRIMER_G1.pdf">
                                                    <Button variant="light" className="border-5" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>1</Button>
                                                </PDFDownloadLink>
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU PRIMER G2" term="1" grupo="2" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_PRIMER_G2.pdf">
                                                    <Button variant="light" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>2</Button>
                                                </PDFDownloadLink>
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU PRIMER G3" term="1" grupo="3" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_PRIMER_G3.pdf">
                                                    <Button variant="light" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>3</Button>
                                                </PDFDownloadLink>
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU PRIMER G4" term="1" grupo="4" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_PRIMER_G4.pdf">
                                                    <Button variant="light" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>4</Button>
                                                </PDFDownloadLink>
                                            </ButtonGroup>
                                        </ListGroup.Item>

                                        <ListGroup.Item className="border-0" style={{ backgroundColor: '#f1f1f1' }}>
                                            <h6 className="mb-0">SEGON</h6>

                                            <ButtonGroup aria-label="Basic example">
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU SEGON G1" term="2" grupo="1" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_SEGON_G1.pdf">
                                                    <Button variant="light" className="border-5" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>1</Button>
                                                </PDFDownloadLink>
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU SEGON G2" term="2" grupo="2" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_SEGON_G2.pdf">
                                                    <Button variant="light" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>2</Button>
                                                </PDFDownloadLink>
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU SEGON G3" term="2" grupo="3" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_SEGON_G3.pdf">
                                                    <Button variant="light" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>3</Button>
                                                </PDFDownloadLink>
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU SEGON G4" term="2" grupo="4" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_SEGON_G4.pdf">
                                                    <Button variant="light" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>4</Button>
                                                </PDFDownloadLink>
                                            </ButtonGroup>
                                        </ListGroup.Item>


                                        <ListGroup.Item className="border-0" style={{ backgroundColor: '#f1f1f1' }}>
                                            <h6 className="mb-0">SEGON</h6>
                                            <ButtonGroup aria-label="Basic example">
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU TERCER G1" term="3" grupo="1" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_TERCER_G1.pdf">
                                                    <Button variant="light" className="border-5" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>1</Button>
                                                </PDFDownloadLink>
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU TERCER G2" term="3" grupo="2" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_TERCER_G2.pdf">
                                                    <Button variant="light" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>2</Button>
                                                </PDFDownloadLink>
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU TERCER G3" term="3" grupo="3" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_TERCER_G3.pdf">
                                                    <Button variant="light" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>3</Button>
                                                </PDFDownloadLink>
                                                <PDFDownloadLink document={<MyDocument docu={lista1} titol="GRAU TERCER G4" term="3" grupo="4" programPlan1="Grado en Cinematografia" programPlan2="Grado en Cinematografia G1127"/>} fileName="GRAU_TERCER_G4.pdf">
                                                    <Button variant="light" style={{ backgroundColor: 'white', borderWidth: '1px', borderColor: 'rgba(0, 0, 0, 0.125)' }}>4</Button>
                                                </PDFDownloadLink>
                                            </ButtonGroup>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2" className="border-0 mb-3">
                                <Accordion.Header>MASTER</Accordion.Header>
                                <Accordion.Body className="border-0 mt-0" style={{ backgroundColor: '#f1f1f1' }}>
                                    <ListGroup >

                                        <ListGroup.Item>ART</ListGroup.Item>
                                        <ListGroup.Item>DIRECCIÓ</ListGroup.Item>
                                        <ListGroup.Item>FOTO</ListGroup.Item>
                                        <ListGroup.Item>MUNTATGE</ListGroup.Item>
                                        <ListGroup.Item>PRODUCCIÓ</ListGroup.Item>

                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3" className="border-0 mb-3">
                                <Accordion.Header>MASTER SUPERIOR</Accordion.Header>
                                <Accordion.Body className="border-0 mt-0" style={{ backgroundColor: '#f1f1f1' }}>
                                    <ListGroup>
                                        <ListGroup.Item>ART</ListGroup.Item>
                                        <ListGroup.Item>DIRECCIÓ</ListGroup.Item>
                                        <ListGroup.Item>FOTO</ListGroup.Item>
                                        <ListGroup.Item>GUIÓ</ListGroup.Item>
                                        <ListGroup.Item>MUNTATGE</ListGroup.Item>
                                        <ListGroup.Item>PRODUCCIÓ</ListGroup.Item>
                                        <ListGroup.Item>SO</ListGroup.Item>
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4" className="border-0 mb-3">
                                <Accordion.Header>MASTER UNI</Accordion.Header>
                                <Accordion.Body className="border-0 mt-0 " style={{ backgroundColor: '#f1f1f1' }}>
                                    <ListGroup horizontal>
                                        <ListGroup.Item>1</ListGroup.Item>
                                        <ListGroup.Item>2</ListGroup.Item>
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5" className="border-0">
                                <Accordion.Header>CURSOS</Accordion.Header>
                                <Accordion.Body className="border-0 mt-0" style={{ backgroundColor: '#f1f1f1' }}>
                                    <ListGroup >
                                        <ListGroup.Item>CONSTRU</ListGroup.Item>
                                        <ListGroup.Item>VFX</ListGroup.Item>
                                    </ListGroup>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Nav>

                </div>
                {showGrau1g1 &&
                    lista1
                        .sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                        .map(x => {
                            if (x.term === 1 && x.grupo === "1" && (x.programPlan === "Grado en Cinematografia" || x.programPlan === "Grado en Cinematografia G1127"))
                                return (
                                    <Row className="m-0 mb-3 p-0 ">
                                        <Col lg={2} md={2} className="m-0 ">
                                            <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                        </Col>
                                        <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                            <Row className="m-0 p-0">
                                                <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                        })
                }
                {showGrau1g2 &&
                    lista1.sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                        .map(x => {
                            if (x.term === 1 && x.grupo === "2" && (x.programPlan === "Grado en Cinematografia" || x.programPlan === "Grado en Cinematografia G1127"))
                                return (
                                    <Row className="m-0 mb-3 p-0 ">
                                        <Col lg={2} md={2} className="m-0 ">
                                            <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                        </Col>
                                        <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                            <Row className="m-0 p-0">
                                                <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                )
                        })
                }
                {showGrau1g3 &&
                    lista1.sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                        .map(x => {
                            if (x.term === 1 && x.grupo === "3" && (x.programPlan === "Grado en Cinematografia" || x.programPlan === "Grado en Cinematografia G1127"))
                                return (
                                    <Row className="m-0 mb-3 p-0 ">
                                        <Col lg={2} md={2} className="m-0 ">
                                            <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                        </Col>
                                        <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                            <Row className="m-0 p-0">
                                                <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                )
                        })
                }
                {showGrau1g4 &&
                    lista1.sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                        .map(x => {
                            if (x.term === 1 && x.grupo === "4" && (x.programPlan === "Grado en Cinematografia" || x.programPlan === "Grado en Cinematografia G1127"))
                                return (
                                    <Row className="m-0 mb-3 p-0 ">
                                        <Col lg={2} md={2} className="m-0 ">
                                            <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                        </Col>
                                        <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                            <Row className="m-0 p-0">
                                                <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                )
                        })
                }
                {showGrau2g1 && lista1
                    .sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                    .map(x => {
                        if (x.term === 2 && x.grupo === "1" && x.programPlan === "Grado en Cinematografia")
                            return (
                                <Row className="m-0 mb-3 p-0 ">
                                    <Col lg={2} md={2} className="m-0 ">
                                        <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                    </Col>
                                    <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                        <Row className="m-0 p-0">
                                            <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                        </Row>
                                    </Col>
                                </Row>

                            )
                    })
                }
                {showGrau2g2 &&
                    lista1.sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                        .map(x => {
                            if (x.term === 2 && x.grupo === "2" && x.programPlan === "Grado en Cinematografia")
                                return (
                                    <Row className="m-0 mb-3 p-0 ">
                                        <Col lg={2} md={2} className="m-0 ">
                                            <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                        </Col>
                                        <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                            <Row className="m-0 p-0">
                                                <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                        })
                }
                {showGrau2g3 &&
                    lista1.sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                        .map(x => {
                            if (x.term === 2 && x.grupo === "3" && x.programPlan === "Grado en Cinematografia")
                                return (
                                    <Row className="m-0 mb-3 p-0 ">
                                        <Col lg={2} md={2} className="m-0 ">
                                            <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                        </Col>
                                        <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                            <Row className="m-0 p-0">
                                                <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                )
                        })
                }
                {showGrau2g4 &&
                    lista1.sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                        .map(x => {
                            if (x.term === 2 && x.grupo === "4" && x.programPlan === "Grado en Cinematografia")
                                return (
                                    <Row className="m-0 mb-3 p-0 ">
                                        <Col lg={2} md={2} className="m-0 ">
                                            <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                        </Col>
                                        <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                            <Row className="m-0 p-0">
                                                <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                )
                        })
                }
                {showGrau3g1 && lista1
                    .sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                    .map(x => {
                        if (x.term === 3 && x.grupo === "1" && x.programPlan === "Grado en Cinematografia")
                            return (
                                <Row className="m-0 mb-3 p-0 ">
                                    <Col lg={2} md={2} className="m-0 ">
                                        <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                    </Col>
                                    <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                        <Row className="m-0 p-0">
                                            <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                        </Row>
                                    </Col>
                                </Row>

                            )
                    })
                }
                {showGrau3g2 &&
                    lista1.sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                        .map(x => {
                            if (x.term === 3 && x.grupo === "2" && x.programPlan === "Grado en Cinematografia")
                                return (
                                    <Row className="m-0 mb-3 p-0 ">
                                        <Col lg={2} md={2} className="m-0 ">
                                            <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                        </Col>
                                        <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                            <Row className="m-0 p-0">
                                                <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                        })
                }
                {showGrau3g3 &&
                    lista1.sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                        .map(x => {
                            if (x.term === 3 && x.grupo === "3" && x.programPlan === "Grado en Cinematografia")
                                return (
                                    <Row className="m-0 mb-3 p-0 ">
                                        <Col lg={2} md={2} className="m-0 ">
                                            <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                        </Col>
                                        <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                            <Row className="m-0 p-0">
                                                <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                )
                        })
                }
                {showGrau3g4 &&
                    lista1.sort((a, b) => (a.nombreCompleto > b.nombreCompleto ? 1 : a.nombreCompleto < b.nombreCompleto ? -1 : 0))
                        .map(x => {
                            if (x.term === 3 && x.grupo === "4" && x.programPlan === "Grado en Cinematografia")
                                return (
                                    <Row className="m-0 mb-3 p-0 ">
                                        <Col lg={2} md={2} className="m-0 ">
                                            <img className="rounded-circle mx-auto d-block border imagenRedonda shadow" src={x.versionData} alt="foto" />
                                        </Col>
                                        <Col lg={10} md={10} className="pe-0 border-start border-dark border-2">
                                            <Row className="m-0 p-0">
                                                <Col lg={11} md={11} className="p-0 fs-6"><p>{x.nombreCompleto}</p></Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                )
                        })
                }

            </Container >
        </>
    )
}

export default LlistesHome