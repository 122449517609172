import React, { useState, useEffect } from 'react';

import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import Login from './Login';

import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebaseConfig/firebase';
import { doc, setDoc, getDocs, collection, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebase';


function Upload() {

    const [lista1, setLista] = useState([]);
    const [preguntes, setPreguntes] = useState([]);
    const [valido, setValido] = useState();
    const [email, setEmail] = useState();
    const [nombre, setNombre] = useState();
    const [listaEmails, setListaEmails] = useState();


    const schema = yup.object().shape({
        nombre: yup.string().required().min(6,),

    });

    const sendEmail = async (values) => {

        try {
            const emails = [];
            emails.push(values);
            console.log(emails)
            const collectionRef = collection(db, 'email');
            emails[0].map(i => {
                const nomSplit = i.split(".");
                const nom = nomSplit[0].charAt(0).toUpperCase() + nomSplit[0].slice(1);
                console.log(nom)
                const emailContent = {
                    to: i,
                    message: {
                        subject: `Hola ${nom}`,
                        html: `<!DOCTYPE html>
                        <html lang="es">
                        
                        <head>
                            <meta charset="UTF-8">
                            <meta http-equiv="X-UA-Compatible" content="IE=edge">
                            <meta name="viewport" content="width=device-width, initial-scale=1.0">
                            <title>Email</title>  
                        </head>
                        
                        <body>
                            <div style="width: 70%; max-width: 700px; margin: auto;">
                                <div style="margin: 0px; padding: 0px">
                                    <img style="width: 100%; border-top-left-radius: 80px ; border-top-right-radius: 80px;"
                                        src="https://form.appsescac.com/images/thisisescac.jpg">
                                </div>
                                <div style="padding-left: 20px; padding-right: 20px; font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif">
                                    <div style="padding-top: 20px;">
                                        <p>Hola ${nom}</p>
                                        <p style="margin-top: 10px; text-align: justify;">
                                            Se han publicado los resultados de las encuestas! 
                                            
                                         </p>
                                         <a href=https://verifica.appsescac.com> Let's Go! </a>
                                         
                                    </div>
                                    
                                    <div style="margin-top: 30px;">
                                        <p style="margin-top: 5px;">Un saludo y muchas gracias.</p>
                                    </div>
                                </div>
                            </div>
                        </body>
                        </html>`,
                    }
                }
                addDoc(collectionRef, emailContent);
                console.log("Email Sended")
            
            })
            
        } catch (error) {
            console.log(error);
        }

    }

    const enviar = async (values) => {

        var date = new Date().getTime();
        date = date.toString();

        try {
            await setDoc(doc(db, 'QuizzRes', date), {
                ...values
            })
            console.log("Sended");
            //console.log(values.nombre)
            const docs = []
            const emails = []
            const body = JSON.parse(values.nombre)
            docs.unshift(body)
            //console.log(docs)
            docs[0].map(i => {
                emails.push(i.Q10)
            })
            const emailsUnicos = [...new Set(emails)];
            //console.log(emailsUnicos)
            setListaEmails(emailsUnicos);
            sendEmail(emailsUnicos);

        } catch (error) {
            console.log(error);
        }
    }



    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {

                setEmail(user.email);
                setNombre(user.displayName);
                setValido(true);

            } else {
                setValido(false);
                console.log("user is logged out")

            }
        });

    }, [])

    useEffect(() => {
        const getLista = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'QuizzRes'))
                const docs = []

                querySnapshot.forEach((doc) => {
                    const body = JSON.parse(doc.data().nombre)
                    docs.unshift(body)

                })
                //console.log(docs)
                setLista(docs);


            } catch (error) {
                console.log(error);
            }
        }
        getLista()
    }, [lista1]);


    useEffect(() => {
        const getLista = async () => {
            try {
                const preRef = collection(db, 'QuizzPreguntes')
                const q = await getDocs(preRef)
                const docs = []

                q.forEach((doc) => {
                    docs.unshift(doc.data())
                })

                setPreguntes(docs);


            } catch (error) {
                console.log(error);
            }
        }
        getLista()
    }, [preguntes]);

    if (valido) return (
        <>
            <Formik
                validationSchema={schema}
                onSubmit={enviar}
                initialValues={{
                    nombre: ''
                }}
                validateOnMont>

                {({ handleSubmit, handleChange, values, errors, isValid }) => (

                    <Form className="p-3" noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="nombre" className="mb-4">

                            <Form.Label className="form-label mb-0">
                                JSON
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={20}
                                name="nombre"
                                className="form-control border-dark ps-1 pb-1 fw-bold"
                                value={values.nombre}
                                onChange={handleChange}

                            />
                        </Form.Group>



                        <Button
                            variant="dark"
                            type="submit"
                            value="Enviar"
                            as="input"
                            disabled={!isValid}
                        //onClick={() => { handleClose(); }}
                        >
                        </Button>
                    </Form>
                )}

            </Formik>
  



        </>
    )

    return (
        <Login />
    )

}

export default Upload