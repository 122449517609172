import React, { useState, useEffect } from 'react';

import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import Login from './Login';

import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../firebaseConfig/firebase';
import { doc, setDoc, getDocs, collection, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig/firebase';


function UploadMasters() {

    const [lista1, setLista] = useState([]);
    const [valido, setValido] = useState();

    const enviar = async (values) => {

        var date = new Date().getTime();
        date = date.toString();

        try {
            await setDoc(doc(db, 'QuizzResMaster', date), {
                ...values
            })
            console.log("Sended");
           
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {

                //setEmail(user.email);
                //setNombre(user.displayName);
                setValido(true);

            } else {
                setValido(false);
                console.log("user is logged out")

            }
        });
    }, [])

    const schema = yup.object().shape({
        nombre: yup.string().required().min(6,),

    });


    if (valido) return (
        <>
            <Formik
                validationSchema={schema}
                onSubmit={enviar}
                initialValues={{
                    nombre: ''
                }}
                validateOnMont>

                {({ handleSubmit, handleChange, values, errors, isValid }) => (

                    <Form className="p-3" noValidate onSubmit={handleSubmit}>
                        <Form.Group controlId="nombre" className="mb-4">

                            <Form.Label className="form-label mb-0">
                                JSON
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={20}
                                name="nombre"
                                className="form-control border-dark ps-1 pb-1 fw-bold"
                                value={values.nombre}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Button
                            variant="dark"
                            type="submit"
                            value="Enviar"
                            as="input"
                            disabled={!isValid}
                        //onClick={() => { handleClose(); }}
                        >
                        </Button>
                    </Form>
                )}
            </Formik>
  



        </>
    )

    return (
        <Login />
    )

}

export default UploadMasters