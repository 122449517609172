import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.css';

import './index.css';

import Login from './components/Login';
import Upload from './components/Upload';
import Resultats from './components/Resultats';
import QuizzProfe from './components/QuizzProfe';
import LlistesHome from './components/LlistesHome';
import UploadMasters from './components/UploadMasters';




function App() {

  return (
    <Router>
      <div>
        <Routes>
  
            <Route path="/login" element={<Login />} />
            <Route path='/upload' element={<Upload />}></Route>
            <Route path='/uploadmaster' element={<UploadMasters />}></Route>
            <Route path='/' element={<Resultats />}></Route>
            <Route path='/quizzprofe' element={<QuizzProfe />}></Route>
            <Route path='/llistes' element={<LlistesHome />}></Route>
            <Route path="*" element={<div>404</div> } />
   
        </Routes>
      </div>
    </Router>
  );
}

export default App;

function NotFound() {
  return <>Ha llegado a una página que no existe</>;
}